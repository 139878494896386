<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1368_113)">
      <path d="M17.75 9.75C17.75 7.76088 16.9598 5.85322 15.5533 4.4467C14.1468 3.04018 12.2391 2.25 10.25 2.25C8.26088 2.25 6.35322 3.04018 4.9467 4.4467C3.54018 5.85322 2.75 7.76088 2.75 9.75C2.75 11.7391 3.54018 13.6468 4.9467 15.0533C6.35322 16.4598 8.26088 17.25 10.25 17.25C12.2391 17.25 14.1468 16.4598 15.5533 15.0533C16.9598 13.6468 17.75 11.7391 17.75 9.75ZM16.3016 17.3953C14.6422 18.7125 12.5375 19.5 10.25 19.5C4.86406 19.5 0.5 15.1359 0.5 9.75C0.5 4.36406 4.86406 0 10.25 0C15.6359 0 20 4.36406 20 9.75C20 12.0375 19.2125 14.1422 17.8953 15.8016L24.1719 22.0781C24.6125 22.5187 24.6125 23.2312 24.1719 23.6672C23.7313 24.1031 23.0188 24.1078 22.5828 23.6672L16.3016 17.3953Z" fill="#0050FF"/>
    </g>
    <defs>
      <clipPath id="clip0_1368_113">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>
